<template>
  <div>
    <div class="container">
      <div class="row justify-content-center" style="margin-top: 50px">
        <div class="col-lg-6">
          <vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#31a05f" />

          <form @submit.prevent="login()">
            <div class="row mt-4">
              <div class="col">
                <div class="font-weight-bold" style="font-size: 20px">Selamat Datang</div>
                <div style="font-weight: 300" class="text-muted mt-2">Silahkan Masuk untuk melanjutkan</div>
              </div>
            </div>

            <div v-if="errorMessageAPI != ''">
              <div class="alert alert-danger mt-4 font-weight-light">
                {{ errorMessageAPI }}
              </div>
            </div>

            <div class="row" style="margin-top: 40px">
              <div class="col" style="font-size: 14px">
                <div class="input-group mb-3">
                  <input type="text" class="form-control py-4" placeholder="Email Anda..." v-model="filled.email" />
                </div>

                <div class="input-group mb-3">
                  <input type="password" class="form-control py-4" placeholder="Password Anda..." v-model="filled.password" />
                </div>

              </div>
            </div>

            <div class="row" style="margin-top: 50px">
              <div class="col">
                <button type="submit" class="btn text-white btn-block py-3 font-weight-bold shadow" style="background-color: #00BF99">Masuk</button>
              </div>
            </div>
          </form>

          <div class="row mt-4" style="font-size: 14px">
            <div class="col">
              <div class="text-center">
                <router-link to="/auth/forgotpassword" class="font-weight-bold text-black" style="color: #00BF99">Lupa Password ?</router-link>
              </div>
              <div class="mt-3 text-center">
                <div><span class="text-muted" style="font-weight: 300">Belum punya akun ?</span> <router-link to="/auth/register" class="font-weight-bold" style="color: #00BF99">Daftar Sekarang!</router-link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoImport from "../../assets/logo.png";
import axios from "axios";
export default {
  name: "LoginViews",

  data() {
    return {
      logoIMG: LogoImport,

      filled: {
        email: "",
        password: "",
      },

      isActive: false,
      errorMessageAPI: "",
    };
  },

  methods: {
    login() {
      this.isActive = true;
      console.log("Login proses");

      this.hitAPILogin();
    },

    errorHitAPI() {
      console.log("terjadi error");
      setTimeout(() => {
        this.isActive = false;
        this.errorMessageAPI = "Sepertinya ada kesalahan. Mohon mencoba kembali";
      }, 5000);
    },

    hitAPILogin() {
      this.errorMessageAPI = "";
      axios
        .post(process.env.VUE_APP_API + "login", {
          email: this.filled.email,
          password: this.filled.password,
        })
        .then((resp) => {
          console.log(resp);
          if(resp.status == 200) {
            let token = resp.data.data.token;
            this.$store.commit('login', token);

            this.$router.push('/');
          }
          this.isActive = false;
        })
        .catch(() => {
          this.errorHitAPI();
        })
    },
  },
};
</script>

<style>
</style>